<template>
  <div>
    <!-- <ModalEditSearchWidget
      v-if="showEditSearchWidget"
      :showModal="showEditSearchWidget"
      :onClickCancel="hideModal"
      :widget="selectedWidget"
      @widgetUpdated="widgetUpdated"
    /> -->

    <ModalAddSearchWidget
      v-if="showAddSearchWidget"
      :showModal="showAddSearchWidget"
      :onClickCancel="hideModal"
      @widgetCreated="widgetCreated"
    />

    <ModalDeleteSearchWidget
      v-if="showDeleteSearchWidget"
      :showModal="showDeleteSearchWidget"
      :onClickCancel="hideModal"
      :widget="selectedWidget"
      @widgetDeleted="widgetDeleted"
    />

    <ui-section-header :showAdd="true" :addFunction="setShowAddSearchWidget">
      <template v-slot:title>{{
        $t(
          'Components.Settings.Widgets.LocationSearchWidgets.Title_SearchWidgets'
        )
      }}</template>
    </ui-section-header>

    <table class="table is-fullwidth is-striped is-hoverable">
      <thead>
        <tr>
          <th>
            {{
              $t('Components.Settings.Widgets.LocationSearchWidgets.Label_Type')
            }}
          </th>
          <th>
            {{
              $t('Components.Settings.Widgets.LocationSearchWidgets.Label_Name')
            }}
          </th>
          <th>
            {{
              $t(
                'Components.Settings.Widgets.LocationSearchWidgets.Label_ChannelName'
              )
            }}
          </th>
          <th>
            {{
              $t('Components.Settings.Widgets.LocationSearchWidgets.Label_Key')
            }}
          </th>
          <th class="has-text-centered">
            {{
              $t(
                'Components.Settings.Widgets.LocationSearchWidgets.Label_ShowCountry'
              )
            }}
          </th>
          <th class="has-text-centered">
            {{
              $t(
                'Components.Settings.Widgets.LocationSearchWidgets.Label_ShowMeetingType'
              )
            }}
          </th>
          <th class="has-text-centered">
            {{
              $t(
                'Components.Settings.Widgets.LocationSearchWidgets.Label_ShowSearch'
              )
            }}
          </th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(widget, index) in widgets" :key="index">
          <td>{{ widget.Type }}</td>
          <td>{{ widget.Name }}</td>
          <td>{{ widget.ChannelName }}</td>
          <td>{{ widget.Key }}</td>
          <td class="has-text-centered">
            <span class="icon">
              <font-awesome-icon
                v-if="widget.ShowCountrySelect"
                :icon="['fas', 'check']"
                class="has-text-success"
              />
              <font-awesome-icon
                v-if="!widget.ShowCountrySelect"
                :icon="['fas', 'times']"
                class="has-text-danger"
              />
            </span>
          </td>
          <td class="has-text-centered">
            <span class="icon">
              <font-awesome-icon
                v-if="widget.ShowMeetingtypeSelect"
                :icon="['fas', 'check']"
                class="has-text-success"
              />
              <font-awesome-icon
                v-if="!widget.ShowMeetingtypeSelect"
                :icon="['fas', 'times']"
                class="has-text-danger"
              />
            </span>
          </td>
          <td class="has-text-centered">
            <span class="icon">
              <font-awesome-icon
                v-if="widget.ShowSearchInput"
                :icon="['fas', 'check']"
                class="has-text-success"
              />
              <font-awesome-icon
                v-if="!widget.ShowSearchInput"
                :icon="['fas', 'times']"
                class="has-text-danger"
              />
            </span>
          </td>
          <td width="75">
            <div class="field is-grouped has-text-right">
              <p class="control">
                <a
                  @click="widgetDetails(widget)"
                  :title="$t('Form.Control.ViewSummary')"
                >
                  <span class="icon">
                    <font-awesome-icon :icon="['fas', 'info-circle']" />
                  </span>
                </a>
              </p>
              <p class="control">
                <a @click="editWidget(widget)" :title="$t('Form.Control.Edit')">
                  <span class="icon">
                    <font-awesome-icon :icon="['fas', 'edit']" />
                  </span>
                </a>
              </p>
              <p class="control">
                <a
                  @click="setShowDeleteSearchWidget(widget)"
                  :title="$t('Form.Control.Delete')"
                >
                  <span class="icon">
                    <font-awesome-icon
                      :icon="['fas', 'trash-alt']"
                      style="color: red"
                    />
                  </span>
                </a>
              </p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import widgetProvider from '@/providers/widget'

const ModalAddSearchWidget = () =>
  import('@/components/Services/Widgets/ModalAddSearchWidget')
const ModalEditSearchWidget = () =>
  import('@/components/Services/Widgets/ModalEditSearchWidget')
const ModalDeleteSearchWidget = () =>
  import('@/components/Services/Widgets/ModalDeleteSearchWidget')

export default {
  components: {
    ModalAddSearchWidget,
    // ModalEditSearchWidget,
    ModalDeleteSearchWidget,
  },

  props: {},

  data() {
    return {
      isLoading: false,
      selectedWidget: null,
      showAddSearchWidget: false,
      showDeleteSearchWidget: false,
      // showEditSearchWidget: false,
      widgets: [],
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
  },

  created() {
    this.getChannelSearchWidgets()
  },

  methods: {
    ...mapMutations('widgetStore', ['setSelectedWidget']),

    getChannelSearchWidgets() {
      let self = this
      self.isLoading = true

      widgetProvider.methods
        .getChannelSearchWidgets(this.channel.Id)
        .then((response) => {
          self.widgets = response.data
        })
        .catch((e) => {
          //console.log(e.response)
        })
        .finally(() => {
          self.isLoading = false
        })
    },

    widgetCreated(widget) {
      let self = this
      self.getChannelSearchWidgets()
    },

    widgetDeleted(widget) {
      let self = this
      self.getChannelSearchWidgets()
    },

    setShowAddSearchWidget() {
      this.showAddSearchWidget = true
    },

    setShowDeleteSearchWidget(widget) {
      this.selectedWidget = widget
      this.showDeleteSearchWidget = true
    },

    widgetDetails(widget) {
      this.setSelectedWidget(widget)

      this.$router.push({
        name: 'services-widgets-details',
        params: { widgetId: widget.Id },
      })
    },

    editWidget(widget) {
      this.setSelectedWidget(widget)

      this.$router.push({
        name: 'services-widgets-details-edit',
        params: { widgetId: widget.Id },
      })
    },

    hideModal() {
      this.showAddSearchWidget = false
      this.showEditSearchWidget = false
      this.showDeleteSearchWidget = false
      this.selectedWidget = null
    },
  },
}
</script>

<style></style>
